import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

// Import your styles

import '../styles/index.css/introduction-section.css';
import '../styles/index.css/hero-section.css';
import '../styles/index.css/with-vyrex-section.css';
import '../styles/index.css/services-overview.css';
import '../styles/index.css/free-section.css';
import '../styles/index.css/pricing-section.css';
import '../styles/index.css/request-call-section.css';
import '../styles/index.css/footer.css';
import '../styles/index.css/mobile.css';


// Define your component
const Home = () => {

    const location = useLocation();



    // Scroll restoration logic
    useEffect(() => {
        const scrollPosition = sessionStorage.getItem('scrollPosition');
        if (scrollPosition) {
            window.scrollTo(0, parseInt(scrollPosition, 10));
        }
        const handleScroll = () => {
            sessionStorage.setItem('scrollPosition', window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [location]);

    return (
        <div>


            <div className="main">
                <div id="hero-section">
                    <video autoPlay muted loop>
                        <source src="videos/Recording 2024-09-07 170558.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

                <div id="introduction-section">
                    <div class="introduction-text">
                        <h1 class="main-headline">Vyrex - Affordable SEO Services</h1>
                        <p>Vyrex is your go-to partner for affordable and effective SEO solutions.</p>
                        <p>
                            We specialize in helping businesses of all sizes improve their online presence and drive more traffic
                            through proven SEO strategies.
                        </p>
                        <p>
                            Whether you're a startup looking to build your brand or an established company seeking to improve
                            visibility, our comprehensive SEO strategies are designed to drive meaningful results.
                        </p>
                    </div>

                    <div class="introduction-animation">
                        <video autoPlay muted loop>
                            <source src="videos/Recording 2024-09-07 171048.mp4" type="video/mp4" />
                        </video>
                    </div>
                </div>



                <div id="with-vyrex-section">
                    <div className="with-vyrex-questions">
                        <p className="question">Want better search rankings?</p>
                        <p className="question">Looking for affordable SEO?</p>
                        <p className="question">Need a boost for your startup?</p>
                        <p className="question">Ready to increase your website traffic?</p>
                    </div>
                    <div className="with-vyrex-tagline">
                        <p>With Vyrex, It’s Possible to Achieve All Your SEO Goals.</p>
                    </div>
                </div>

                <div id="services-overview">
                    <h2>Our SEO Services</h2>
                    <div className="services-container">
                        <div className="services-list">
                            {/* First Video */}
                            <div className="services-video">
                                <video autoPlay muted loop>
                                    <source src="videos/Recording 2024-09-07 203151.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>

                            {/* Service Items */}
                            <div className="service-item">
                                <h3>Keyword Research</h3>
                                <p>
                                    <img
                                        src="https://img.icons8.com/ios-filled/20/4caf50/checkmark.png"
                                        alt="checkmark"
                                        className="checkmark"
                                    />{' '}
                                    Identify high-impact keywords to drive targeted traffic
                                </p>
                                <p>
                                    <img
                                        src="https://img.icons8.com/ios-filled/20/4caf50/checkmark.png"
                                        alt="checkmark"
                                        className="checkmark"
                                    />{' '}
                                    Analyze competition to find the best opportunities for growth
                                </p>
                                <p>
                                    <img
                                        src="https://img.icons8.com/ios-filled/20/4caf50/checkmark.png"
                                        alt="checkmark"
                                        className="checkmark"
                                    />{' '}
                                    Focus on long-tail keywords to maximize ROI for startups
                                </p>
                            </div>

                            <div className="service-item">
                                <h3>On-Page Optimization</h3>
                                <p>
                                    <img
                                        src="https://img.icons8.com/ios-filled/20/2196f3/checkmark.png"
                                        alt="checkmark"
                                        className="checkmark"
                                    />{' '}
                                    Enhance meta tags and headings to boost search rankings
                                </p>
                                <p>
                                    <img
                                        src="https://img.icons8.com/ios-filled/20/2196f3/checkmark.png"
                                        alt="checkmark"
                                        className="checkmark"
                                    />{' '}
                                    Update content to improve relevance and engagement
                                </p>
                                <p>
                                    <img
                                        src="https://img.icons8.com/ios-filled/20/2196f3/checkmark.png"
                                        alt="checkmark"
                                        className="checkmark"
                                    />{' '}
                                    Optimize images to enhance load times and performance
                                </p>
                            </div>

                            {/* Second Video */}
                            <div className="services-video">
                                <video autoPlay muted loop>
                                    <source src="videos/Recording 2024-09-07 203315.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>

                            <div className="service-item">
                                <h3>Technical SEO</h3>
                                <p>
                                    <img
                                        src="https://img.icons8.com/ios-filled/20/f44336/checkmark.png"
                                        alt="checkmark"
                                        className="checkmark"
                                    />{' '}
                                    Acquire backlinks through affordable strategies
                                </p>
                                <p>
                                    <img
                                        src="https://img.icons8.com/ios-filled/20/f44336/checkmark.png"
                                        alt="checkmark"
                                        className="checkmark"
                                    />{' '}
                                    Build relationships with industry influencers for credible links
                                </p>
                                <p>
                                    <img
                                        src="https://img.icons8.com/ios-filled/20/f44336/checkmark.png"
                                        alt="checkmark"
                                        className="checkmark"
                                    />{' '}
                                    Utilize guest posts to enhance site authority
                                </p>
                            </div>

                            <div className="service-item">
                                <h3>Link Building</h3>
                                <p>
                                    <img
                                        src="https://img.icons8.com/ios-filled/20/f44336/checkmark.png"
                                        alt="checkmark"
                                        className="checkmark"
                                    />{' '}
                                    Improve site speed using low-cost methods
                                </p>
                                <p>
                                    <img
                                        src="https://img.icons8.com/ios-filled/20/f44336/checkmark.png"
                                        alt="checkmark"
                                        className="checkmark"
                                    />{' '}
                                    Fix crawl errors with free webmaster tools
                                </p>
                                <p>
                                    <img
                                        src="https://img.icons8.com/ios-filled/20/f44336/checkmark.png"
                                        alt="checkmark"
                                        className="checkmark"
                                    />{' '}
                                    Optimize site structure for better navigation
                                </p>
                            </div>

                            <div className="service-item">
                                <h3>Local SEO</h3>
                                <p>
                                    <img
                                        src="https://img.icons8.com/ios-filled/20/f44336/checkmark.png"
                                        alt="checkmark"
                                        className="checkmark"
                                    />{' '}
                                    Optimize your Google My Business profile for visibility
                                </p>
                                <p>
                                    <img
                                        src="https://img.icons8.com/ios-filled/20/f44336/checkmark.png"
                                        alt="checkmark"
                                        className="checkmark"
                                    />{' '}
                                    Build local citations in free or affordable directories
                                </p>
                                <p>
                                    <img
                                        src="https://img.icons8.com/ios-filled/20/f44336/checkmark.png"
                                        alt="checkmark"
                                        className="checkmark"
                                    />{' '}
                                    Target local keywords to attract nearby customers
                                </p>
                            </div>

                            <div className="service-item">
                                <h3>Content Marketing</h3>
                                <p>
                                    <img
                                        src="https://img.icons8.com/ios-filled/20/f44336/checkmark.png"
                                        alt="checkmark"
                                        className="checkmark"
                                    />{' '}
                                    Create engaging blog posts to boost organic traffic
                                </p>
                                <p>
                                    <img
                                        src="https://img.icons8.com/ios-filled/20/f44336/checkmark.png"
                                        alt="checkmark"
                                        className="checkmark"
                                    />{' '}
                                    Optimize content for SEO and audience engagement
                                </p>
                                <p>
                                    <img
                                        src="https://img.icons8.com/ios-filled/20/f44336/checkmark.png"
                                        alt="checkmark"
                                        className="checkmark"
                                    />{' '}
                                    Develop targeted content strategies to improve brand awareness
                                </p>
                                <p>
                                    <img
                                        src="https://img.icons8.com/ios-filled/20/f44336/checkmark.png"
                                        alt="checkmark"
                                        className="checkmark"
                                    />{' '}
                                    Leverage content to establish authority in your industry
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="free-section">
                    <div className="free-content">
                        <div className="free-header">
                            <h2>Get Started with Our Free Lite Plan</h2>
                            <p>
                                We offer a complimentary website assessment with actionable insights and recommendations. No
                                strings attached—just valuable information to help you improve your online presence.
                            </p>
                        </div>
                        <div className="free-benefits">
                            <div className="benefit-item">
                                <img
                                    src="https://img.icons8.com/ios-filled/50/004d40/inspection.png"
                                    alt="Review"
                                    className="benefit-icon"
                                />
                                <span>Thorough Website Review</span>
                            </div>
                            <div className="benefit-item">
                                <img
                                    src="https://img.icons8.com/ios-filled/50/004d40/seo.png"
                                    alt="SEO Issues"
                                    className="benefit-icon"
                                />
                                <span>Identify Key SEO Issues</span>
                            </div>
                            <div className="benefit-item">
                                <img
                                    src="https://img.icons8.com/ios-filled/50/004d40/recommendation.png"
                                    alt="Recommendations"
                                    className="benefit-icon"
                                />
                                <span>Customized Recommendations</span>
                            </div>
                        </div>
                        <Link to="/Pricing" href="/pricing" className="cta-button">
                            Get Started for Free
                        </Link>
                    </div>
                </div>

                <div className="pricing-section">
                    <h2>Our Pricing Plans</h2>
                    <div className="pricing-container">

                        {/* Lite Plan */}
                        <div className="pricing-card lite-plan">
                            <h3>Lite Plan (Free)</h3>
                            <p className='price'>Free</p>
                            <ul className='features'>
                                <li>Free Website Audit</li>
                                <li>Keyword Research (5 Keywords)</li>
                                <li>SEO Performance Summary</li>
                            </ul>
                            <Link to="/FreeSection" className="cta-button">Get Started</Link>
                        </div>

                        {/* Basic Plan */}
                        <div className='pricing-card basic-plan'>
                            <h3>Basic Plan</h3>
                            <p className='price'>$250/month</p>
                            <ul className='features'>
                                <li>On-Page SEO Optimization</li>
                                <li>Technical SEO Audit</li>
                                <li>Monthly Performance Reporting</li>
                            </ul>
                            <Link to='/Pricing' className='cta-button'>Explore Plans</Link>
                        </div>

                        {/* Standard Plan */}
                        <div className="pricing-card standard-plan">
                            <h3>Standard Plan</h3>
                            <p className="price">$450/month</p>
                            <ul className="features">
                                <li>Enhanced On-Page SEO</li>
                                <li>Keyword Research & Content Optimization</li>
                                <li>Detailed Monthly Performance Reporting</li>
                            </ul>
                            <Link to="/Pricing" className="cta-button">Explore Plans</Link>
                        </div>

                    </div>
                </div>



                <div className="request-call-section">
                    <h2>Schedule a Free Consultation</h2>
                    <p>
                        Speak with our SEO specialists today to discuss how Vyrex can help you achieve your digital marketing
                        goals.
                    </p>
                    <Link to="/call" className="cta-button">
                        Request a Call
                    </Link>
                </div>


                <footer className="footer">
                    <div className="footer-section footer-logo">
                        <img src="images/3.png" alt="Vyrex Logo" className="logo" />
                    </div>
                    <div className="footer-container">
                        {/* Quick Links */}
                        <div className="footer-section footer-links">
                            <h4>Quick Links</h4>
                            <ul>
                                <li><a href="#hero-section">Home</a></li>
                                <li><a href="#introduction-section">About Us</a></li>
                                <li><a href="#services-overview">Services</a></li>
                                <li><Link to='Call'>Connect with us </Link ></li>
                                <li><a href="/Pricing">Pricing</a></li>
                            </ul>
                        </div>

                        {/* Legal Links */}
                        <div className="footer-section footer-legal">
                            <h4>Legal</h4>
                            <ul>
                                <li><Link to='/TermsAndConditions'>Terms of Service</Link></li>

                                <li><Link to="/Discover#faq-section">FAQ</Link></li>
                            </ul>
                        </div>

                        {/* Social Media Links */}
                        <div className="footer-section footer-social">
                            <h4>Follow Us</h4>
                            <div className="social-icons">
                                <a href="https://www.linkedin.com/company/vyrex-seo" target="_blank" rel="noopener noreferrer">
                                    <img src="https://img.icons8.com/ios-filled/30/ffffff/linkedin.png" alt="LinkedIn" />
                                </a>
                                <a href="https://x.com/VyrexOfficial" target="_blank" rel="noopener noreferrer">
                                    <img src="https://img.icons8.com/ios-filled/30/ffffff/twitter.png" alt="Twitter" />
                                </a>
                                <a href="https://www.facebook.com/share/u64nanas4ZY8icGJ/" target="_blank" rel="noopener noreferrer">
                                    <img src="https://img.icons8.com/ios-filled/30/ffffff/facebook-new.png" alt="Facebook" />
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* Divider */}
                    <hr className="footer-divider" />

                    {/* Copyright */}
                    <div className="footer-bottom">
                        <p>© 2024 Vyrex. All rights reserved.</p>
                    </div>
                </footer>
            </div>
        </div>
    );
}

export default Home;
